<template>
    <div class="up_content_inner up_add_new_site">
        <div class="up_main_container_sm">
            <div v-if="!addNewStep.complete" class="up_site_add_container">
                <div class="up_page_header">
                    <h2 class="up_page_title">Add New Site</h2>
                </div>
                <div class="up_site_step p-mt-3 p-mb-6">
                    <ul>
                        <li :class="[addNewStep.websiteDetails ? 'active' : '']">Website Details</li>
                        <li :class="[addNewStep.payment ? 'active' : '']">Payment</li>
                    </ul>
                </div>
                <div v-if="addNewStep.websiteDetails" class="up_website_details">
                    <div class="p-d-flex p-justify-end p-mb-3">
                        <Button :disabled="addwebsiteData.disableButton" type="button" label="Next" class="p-button-primary p-mr-2" @click="WebsiteAddStep('step1')" />
                    </div>
                    <div v-if="!packageType.additionalService" class="up_website_details_section up_service_type p-mb-5">
                        <h3>Service Type</h3>
                        <div class="up_website_details_inner">
                            <div class="up_type_col">
                                <h4>Prime Service</h4>
                                <div class="p-field-radiobutton" v-for="type of packageType.prime" :key="type.uuid">
                                    <RadioButton :id="type.uuid" name="service" :value="type.name" v-model="addwebsiteData.service" />
                                    <label :for="type.uuid">{{type.name}}</label>
                                </div>
                            </div>
                            <div class="up_type_col">
                                <h4>Combo Service</h4>
                                <div class="p-field-radiobutton" v-for="type of packageType.combo" :key="type.uuid">
                                    <RadioButton :id="type.uuid" name="service" :value="type.name" v-model="addwebsiteData.service" />
                                    <label :for="type.uuid">{{type.name}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="up_website_details_section up_info p-mb-5">
                        <h3>Basic Info</h3>
                        <div class="up_website_details_inner">
                            <h4>Service Type</h4>
                            <div v-if="packageType.additionalService" class="p-formgroup-inline">
                                <div class="p-field-radiobutton" v-for="type of packageType.additional" :key="type.uuid">
                                    <RadioButton :id="type.uuid" name="service" :value="type.name" v-model="addwebsiteData.service" />
                                    <label :for="type.uuid">{{type.name}}</label>
                                </div>
                            </div>
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col">
                                    <label for="websiteName">Website Name<span class="text-danger">*</span></label>
                                    <InputText id="websiteName" ref="webname" type="text" v-model="addwebsiteData.name" @change="changeInputValue()" />
                                    <small class="p-invalid text-danger" v-if="addwebsiteDataError.invalidName">Website Name is In-valid.</small>
                                </div>
                                <div class="p-field p-col">
                                    <label for="websiteUrl">Website URL ( e.g. truabilities.com )<span class="text-danger">*</span></label>
                                    <InputText id="websiteUrl" type="text" v-model="addwebsiteData.url" @change="changeInputValue()" />
                                    <small class="p-invalid text-danger" v-if="addwebsiteDataError.invalidUrl">Website URL is In-valid.</small>
                                </div>
                            </div>
                        </div>
                        <small class="p-invalid text-danger" v-if="apiResponse.step1Status !== 200">{{apiResponse.step1Message}}</small>
                    </div>
                    <div class="up_website_details_section up_info">
                        <h3>Rate Tiers</h3>
                        <RateTiers />
                    </div>
                    <div class="p-d-flex p-justify-end p-mb-3">
                        <Button :disabled="addwebsiteData.disableButton" type="button" label="Next" class="p-button-primary p-mr-2" @click="WebsiteAddStep('step1')" />
                    </div>
                </div>
                <div v-if="addNewStep.payment" class="up_payment">
                    <div class="up_payment_box">
                        <div v-if=" addNewStep.editWebsiteData !== null && addNewStep.editWebsiteData.services !== 'incompleteEdit'" class="up_additional_service">
                            <h3>Select Service</h3>
                            <div class="p-formgroup-inline">
                                <div class="p-field-checkbox" v-for="type of packageType.additional" :key="type">
                                    <Checkbox :id="type.value" name="service" :value="type.value" v-model="addwebsiteData.additionalService" />
                                    <label :for="type.value">{{type.name}}</label>
                                </div>
                                <small class="p-invalid text-danger" v-if="addwebsiteDataError.additionalService">No additional service seleted.</small>
                            </div>
                        </div>
                        <div class="add_card_header">
                            <h3>{{!userCardStatus ? 'Add New Card' : 'Your Saved Card'}}</h3>
                            <div class="p-mb-2" v-if="!userCardStatus">
                                <Button icon="pi pi-plus" label="Add Card"  class="p-button-info  btn-align-center" @click="addNewStep.billingModal = true" />
                            </div>
                        </div>
                       
                        <!-- <div v-if="userCardStatus && addwebsiteData.card" class="up_payment_card" >
                            <div class="up_card_details up_card_selected" :data-name="addwebsiteData.card.isPrimary ? 'Seleted' : ''">
                                <img :src="imageUrlFrontEnd + 'images/' + [addwebsiteData.card.brand === 'Visa' ? 'visa.jpg' : addwebsiteData.card.brand === 'MasterCard' ? 'master_card.png' : 'amex.png']" width="40" height="25">
                                {{addwebsiteData.card.brand}} - {{addwebsiteData.card.last4}} Expiry - {{addwebsiteData.card.exp_month}}/{{addwebsiteData.card.exp_year}}
                            </div>
                        </div> -->
                        <div class="new_cardtype_list">
                          <div class="new_single_card"  v-if="userCardStatus && addwebsiteData.card">
                            <div :class="['card_inner_details', addwebsiteData.card.brand === 'Visa' ? 'visa-bg-wrapper' : addwebsiteData.card.brand === 'MasterCard' ? 'master-card-wrapper' : 'amex-wrapper' ]" :data-name="addwebsiteData.card.isPrimary ? 'Seleted' : ''">
                                <div class="add_card_type">
                                <div class="left">
                                    <div class="card-chip">
                                        <!-- <img :src="imageUrlFrontEnd + 'images/chip-card.png' " width="60" height="35"> -->
                                        <img src="https://truabilities-dev.s3.us-west-1.amazonaws.com/Media/Users/chip-card.png?AWSAccessKeyId=AKIAZJ4VKLIYQDJKT4WU&Expires=1966641878&Signature=HbSgd%2BZmCtxzMWHh3i7OahILs74%3D" >
                                    </div>
                                </div>
                                <div class="right">Credit Card</div>
                            </div>
                            <div class="card-number-new">
                                <span>**** **** ****</span> {{addwebsiteData.card.last4}}
                            </div>
                            <div class="card-with-auth-name">
                                <div class="card-validity">
                                    <span class="validity-data">
                                        <span class="valid-heading">Valid</span> {{addwebsiteData.card.exp_month}}/{{addwebsiteData.card.exp_year}}
                                    </span>
                                    <div class="card-type-logo">
                                        <img :src="imageUrlFrontEnd + 'images/' + [addwebsiteData.card.brand === 'Visa' ? 'visa.jpg' : addwebsiteData.card.brand === 'MasterCard' ? 'master_card.png' : 'amex.png']" width="60" height="35">
                                    </div>
                                </div>
                            </div>
                            </div>
                          </div>
                        </div>
                        
                        
                        <h3 v-if="!addNewStep.editWebsitePayment">Have Coupon Code?</h3>
                        <div v-if="!addNewStep.editWebsitePayment" class="up_coupon_row">
                            <div class="p-fluid p-formgrid p-grid p-align-center">
                                <div class="p-field p-col p-mb-0">
                                    <InputText v-model="addwebsiteData.coupon" id="coupon" type="text" placeholder="Enter Coupon Code" />
                                    <!-- <small class="p-invalid text-danger" v-if="response.data.status === 400" label = "response.data.message"></small> -->
                                    <small v-if="couponPayloadData.status === 200" class="text-success"><i class="pi pi-check text-success"></i>{{couponPayloadData.message}}</small>
                                    <small v-if="couponPayloadData.status === 400" class="text-danger">{{couponPayloadData.message}}</small>
                                </div>
                                <Button :disabled="addwebsiteData.coupon === ''" icon="pi pi-check" class="p-button-rounded p-button-primary" @click="applyCoupon()" />
                            </div>
                        </div>
                        <div class="up_payment_details p-mb-5">
                            <div class="up_payment_details_inner">
                                <div class="up_payment_details_row p-d-flex p-align-center">
                                    <div class="up_payment_details_col p-col-9">Tier:</div>
                                    <div class="up_payment_details_col p-col-3 p-text-right">Free</div>
                                </div>
                                <div class="up_payment_details_row p-d-flex p-align-center">
                                    <div class="up_payment_details_col p-col-9">100 pageviews <br /> Tier Price/month:</div>
                                    <div class="up_payment_details_col p-col-3 p-text-right">$0.00</div>
                                </div>
                                <div class="up_payment_details_row up_payment_details_total p-d-flex p-align-center">
                                    <div class="up_payment_details_col p-col-9 p-text-right">Total Due Today:</div>
                                    <div class="up_payment_details_col p-col-3 p_payment_total_amount p-text-right">$0.00</div>
                                </div>
                            </div>
                        </div>
                        <div class="up_payment_note">
                            <small class="p-mb-1 p-d-block">*First 100 pageviews will be free for this website.</small><br />
                            <small>**Once the pageviews cross over 100 times, it will be automatically charged monthly as per our rate tiers.</small>
                        </div>
                        <div class="up_payment_actions p-d-flex p-justify-center p-mt-5">
                            <Button icon="pi pi-check" v-if="addNewStep.editWebsiteData === null" type="button" label="Order" class="p-button-primary p-mr-2" @click="WebsiteAddStep('step2')" />
                            <Button icon="pi pi-check" v-else-if="addNewStep.editWebsiteData !== null && addNewStep.editWebsiteData.payment" type="button" label="Order" class="p-button-primary p-mr-2" @click="WebsiteAddStep('serviceAdd')" />
                            <Button icon="pi pi-angle-left" v-else type="button" label="Order" class="p-button-primary p-mr-2" @click="WebsiteAddStep('edit')" />
                            <Button type="button" icon="pi pi-angle-left" label="Back" class="p-button-info p-mr-2" @click="backtoDetails()" />
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="addNewStep.complete" class="up_siteadd_complete_container">
                <div class="up_site_success bg-success text-white p-py-2 p-px-2 p-mb-3">New website created successfully.</div>
                <div class="up_install_process p-grid p-d-flex">
                    <div class="up_license_code p-col-5">
                        <h4>COPY AND PASTE THIS CODE</h4>
                        <div class="p-field p-col p-px-0 p-mt-2">
                            <Textarea name="licenseCode" v-model="addwebsiteData.licenseCode" cols="20" rows="5" readonly />
                        </div>
                        <Button @click="openInstallationPopup()" label="Need Help? Have experts install" class="p-button-primary up_need_help" />
                    </div>
                    <div class="up_install_instructions p-col-7">
                        <Accordion :activeIndex="0">
                            <AccordionTab header="Installation Instructions without GTM">
                                <table class="up_website_creation">
                                    <tbody>
                                        <tr>
                                            <td>Step 1</td>
                                            <td>Copy the <code>&lt;script&gt;</code> tag.</td>
                                        </tr>
                                        <tr>
                                            <td>Step 2</td>
                                            <td>Open your file manager or any FTP client, find the <code>&lt;/body&gt;</code> tag in your website source code.</td>
                                        </tr>
                                        <tr>
                                            <td>Step 3</td>
                                            <td>Paste the <code>&lt;script&gt;</code> before the immediate closing of <code>&lt;/body&gt;</code> tag.</td>
                                        </tr>
                                        <tr>
                                            <td>Step 4</td>
                                            <td>Save this file, upload the file to server if necessary.</td>
                                        </tr>
                                        <tr>
                                            <td>Step 5</td>
                                            <td>Reload the website after clearing all you cookies and cached data from browser and from your server if it has caching mechanism on place.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </AccordionTab>
                            <AccordionTab header="Installation Instructions with GTM">
                                <table class="up_website_creation">
                                    <tbody>
                                        <tr>
                                            <td>Step 1</td>
                                            <td>Copy the code snippet and place within your GTM console.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>
                
            </div>
        </div>
        
        <!-- Billing Details Modal -->
        <BillingDetailsModal v-if="addNewStep.billingModal" :visible="addNewStep.billingModal" @hide="closeDialog()" />
        <!-- Billing Details Modal -->
        <!-- Installation Modal Start -->
        <Dialog class="installation_detials_popup" header="Installation Request Details" v-model:visible="installationPopup.isOpen"  :modal="true">
            <div class="up_installation_container">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="u_name">Name<span class="text-danger">*</span></label>
                        <InputText v-model="installationPopup.name" id="u_name" type="text" readonly />
                    </div>
                    <div class="p-field p-col-6">
                        <label for="u_email">Email Address<span class="text-danger">*</span></label>
                        <InputText v-model="installationPopup.email" id="u_email" type="text" readonly />
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="u_siteName">Website Name<span class="text-danger">*</span></label>
                        <InputText v-model="installationPopup.siteName" id="u_siteName" type="text" readonly />
                    </div>
                    <div class="p-field p-col-6">
                        <label for="u_siteUrl">Website Url<span class="text-danger">*</span></label>
                        <InputText v-model="installationPopup.siteUrl" id="u_siteUrl" type="text" readonly />
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="u_siteUrl">Message<span class="text-danger">*</span></label>
                        <Textarea v-model="installationPopup.message" id="install-message" />
                        <small class="p-invalid sup_text_danger text-danger" v-if="installationPopup.messageEmpty">Message is not available.</small>
                    </div>
                </div>
            </div>
            <template #footer>
                <Button label="Submit" icon="pi pi-check" class="p-button-primary p-mr-2" @click="submitInstallationRequest()" autofocus />
                <Button label="Cancel" icon="pi pi-times" @click="installationPopup.isOpen = false" class="p-button-info p-mr-2"/>
            </template>
        </Dialog>
        <!-- Installation Modal End -->
        <!-- Ntofication Message Showing Element Start -->
        <Toast position="top-right" />
        <!-- Ntofication Message Showing Element End -->
    </div>
</template>

<script>
import { onBeforeMount, reactive, computed } from 'vue';
import { userPortalPackageType, userPortalWebsite, userPortalValidateCoupon, userPortalWebsiteService, userPortalCardList, userPortalWebsiteInstallationGuide } from '../../../../config/appUrls';
import { imageUrlFrontEnd, apiKey, scriptTag, cryptoSecret } from "../../../../config/constant";
import axios from "axios";
import { useStore } from 'vuex';
import { useRoute } from "vue-router";
import { isUrlValid } from '../../../../helper/utility/utility';
import RateTiers from '../../views/RateTiers.vue';
import BillingDetailsModal from '../../popups/BillingDetails.vue';
import router from "../../../../router/index";
import SimpleCrypto from "simple-crypto-js";
import { useToast } from "primevue/usetoast";

const simpleCrypto = new SimpleCrypto(cryptoSecret);

export default {
    name: "AddNewSite",
    components: {
        RateTiers,
        BillingDetailsModal
    },
    setup(){
        //Application store call
        const store = useStore();

        //Route call
        const route = useRoute();

        //Toast Message
        const toast = useToast();

        //Website adding and editing website initial state
        const addNewStep = reactive({
            websiteDetails: true,
            payment: false,
            complete: false,
            editWebsiteData: null,
            editWebsitePayment: false,
            billingModal: false
        });


        //Website package storing state
      
         const packageType = reactive({
            prime: [],
            combo: [],
            additionalService: false,
            additional: []
        });


        //After submit api response store
        const apiResponse = reactive({
            step1Status: '',
            step1Message: '',
            FinalSubmitStatus: '',
            FinalSubmitMessage: '',
        })

        //Add website form storing object 
        const addwebsiteData = reactive({
            service: '',
            name: '',
            url: '',
            coupon: '',
            disableButton: false,
            licenseCode:'',
            additionalService: '',
            card: ''
        });

        const couponPayloadData = reactive({
            status : null,
            message : '',
        })

        //Website form validation state
        const addwebsiteDataError = reactive({
            invalidName: false,
            invalidUrl: false,
            additionalService: false,
        })

        const userCardStatus = computed(() => store.getters.customerDetails.userCard);
        function closeDialog(){
            fetchCard();
        }

        //Installation popup managing states
        const installationPopup = reactive({
            isOpen : false,
            name : '',
            email: '',
            siteName: '',
            siteUrl: '',
            message: '',
            messageEmpty: false,
        })

        onBeforeMount(()=>{
            if (Object.keys(route.params).length > 0 && route.path === "/customer/edit-website") {
                addNewStep.editWebsiteData = route.params.websiteData !== undefined ? JSON.parse(route.params.websiteData) : null;
                let dt = JSON.parse(route.params.websiteData);
                if(dt.payment){
                    let myArr = [
                        {name: "Cookie Consent Widget", value: "cookies"},
                        {name: "CCPA Process", value: "privacyForm"}
                    ];
                    if(dt.services !== null){
                        for (const key in myArr) {
                            if (Object.hasOwnProperty.call(myArr, key)) {
                                if(myArr[key].value === dt.services[0].Servicetype.serviceName){
                                    myArr.splice(key, 1);
                                }
                            }
                        }
                    }
                    packageType.additional = myArr;
                }
                
                addNewStep.editWebsitePayment = addNewStep.editWebsiteData && addNewStep.editWebsiteData.payment ? true : false;
            } else if (route.path === "/customer/additional-services") {
                packageType.additionalService = true;
                packageType.additional = [
                        {name: "Cookie Consent Widget", value: "cookies"},
                        {name: "CCPA Process", value: "privacyForm"}
                ]
                
            } else {
                router.push({name: "CustomerAddWebsite"});
                packageType.additional = [
                        {name: "Cookie Consent Widget", value: "cookies"},
                        {name: "CCPA Process", value: "privacyForm"}
                ]
               
            }
            fetchPackageType();
            changeInputValue();
            fetchCard();
            addwebsiteData.service = 'ADA Widget';
            if (addNewStep.editWebsiteData !== null) {
                addNewStep.websiteDetails = false;
                addNewStep.payment = true;
            }
            // if (addNewStep.editWebsiteData.services === null) {
            //     serviceArr = [
            //         {name: "Cookie Consent Widget", value: "cookies"},
            //         {name: "CCPA Process", value: "privacyForm"}
            //     ]
            // } else {
            //     serviceArr = [
            //         {name: "CCPA Process", value: "privacyForm"}
            //     ]
            // }
        });

        /**
         * Fatching package types form DB
         */
        const fetchPackageType = async() => {
            const respone = await axios.get( userPortalPackageType,  {
                headers: {
                  apiKey: apiKey,
                  token: store.getters.customerPortalToken
                },
            });
            let serviceType = respone.data.data;
            
            for (let i = 0; i < serviceType.length - 2; i++) {
                packageType.prime.push(serviceType[i]);
            }
            let comboService = serviceType.slice(3,5);
            for (let i = 0; i < comboService.length; i++) {
                packageType.combo.push(comboService[i]);
            }
        }

        /**
         * Fatching default card
         */
        const fetchCard = async() => {
            try{
                const respone = await axios.get( userPortalCardList,  {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.customerPortalToken
                    }
                });
                if (respone.data.status === 200) {
                    let card = simpleCrypto.decrypt(respone.data.data);
                    for (let i = 0; i < card.length; i++) {
                        if (card[i].isPrimary) {
                            addwebsiteData.card = card[i];
                        }
                    }
                }
            } catch(err){
                console.error(err);
            }
        }

        const changeInputValue = () => {
            addwebsiteData.disableButton = addwebsiteData.name === '' || addwebsiteData.url === '' ? true : false;
        }

        /**
         * Website add step 1 form validation
         */
        const step1Validate = () =>{
            if (addwebsiteData.name.length < 3 || addwebsiteData.name.length > 50) {
                addwebsiteDataError.invalidName = true;
                // document.getElementById("websiteName").focus()
                // alert("THis is validation error");
                // const esp = document.querySelectorAll("#websiteName");

                // if(esp.length <=6){
                //     console.log("Validation issue");
                //     this.websiteName
                // }
            } else {
                addwebsiteDataError.invalidName = false;
            }
            
            if (!isUrlValid(addwebsiteData.url)) {
                addwebsiteDataError.invalidUrl = true;
                // document.getElementById("websiteUrl").focus()
            } else {
                addwebsiteDataError.invalidUrl = false;
            }

            if (!addwebsiteDataError.invalidName && !addwebsiteDataError.invalidUrl) {
                return true;
            } else {
                return false;
            }
        }

        /**
         * Website adding step wise api call
         */
        const WebsiteAddStep = async(steps) => {
            //Service Type Set
            let serviceType = addwebsiteData.service === 'ADA/Cookie Consent/CCPA Bundle' ? 'ada_cookies_privacyForm' : addwebsiteData.service === 'ADA & Cookie Consent Bundle' ? 'ada_cookies' : addwebsiteData.service === 'ADA Widget' ? 'ada' : addwebsiteData.service === 'Cookie Consent Widget' ? 'cookies' : 'privacyForm';
            //Service For Set
            let serviceFor = addwebsiteData.service === 'ADA/Cookie Consent/CCPA Bundle' || addwebsiteData.service === 'ADA & Cookie Consent Bundle' ? 'both' : addwebsiteData.service === 'ADA Widget' ? 'ada' : 'service';
            let isValidate = step1Validate();
            if (steps === 'edit') {
                isValidate = true;
            }
            let getTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            if (steps !== 'serviceAdd') {
                if (isValidate) {
                    try{
                        const response = await axios.post( userPortalWebsite, {
                            serviceType: steps !== 'edit' ? serviceType : addNewStep.editWebsiteData.type,
                            serviceFor: steps !== 'edit' ? serviceFor : addNewStep.editWebsiteData.serviceFor,
                            websiteName: steps !== 'edit' ? addwebsiteData.name : addNewStep.editWebsiteData.name,
                            websiteUrl: steps !== 'edit' ? addwebsiteData.url : addNewStep.editWebsiteData.url,
                            couponeCode : (steps === 'step1') ? '' : (addwebsiteData.coupon !== null && couponPayloadData.status === 200) ? addwebsiteData.coupon : 'N/A',
                            timeZone: getTimeZone
                        }, {
                            headers: {
                                apiKey: apiKey,
                                token: store.getters.customerPortalToken
                            },
                        });
                        if (response.data.status === 200) {
                            if(steps === 'step1'){
                                addNewStep.websiteDetails = false;
                                addNewStep.payment = true;
                            } else {
                                addNewStep.payment = false;
                                addNewStep.complete = true;
                                let split = scriptTag.split("widgetkey=");
                                addwebsiteData.licenseCode = split[0] + "widgetkey=" + response.data.data.license + split[1];
                                
                            }
                        }
                    } catch (err) {
                        apiResponse.step1Status = err.response.data.status;
                        apiResponse.step1Message = err.response.data.message;
                    }
                }
            } else {
                if (addwebsiteData.additionalService !== '') {
                    addwebsiteDataError.additionalService = false;
                    try{
                        const response = await axios.put( userPortalWebsiteService, {
                            serviceType: addwebsiteData.additionalService
                        }, {
                            params: {
                                uuid: addNewStep.editWebsiteData.uuid
                            },
                            headers: {
                                apiKey: apiKey,
                                token: store.getters.customerPortalToken
                            },
                        });
                        if (response.data.status === 200) {
                            addNewStep.payment = false;
                            addNewStep.complete = true;
                            let split = scriptTag.split("widgetkey=");
                            addwebsiteData.licenseCode = split[0] + "widgetkey=" + response.data.data.License.licenseKey + split[1];
                        }
                    } catch (err) {
                        apiResponse.step1Status = err.response.data.status;
                        apiResponse.step1Message = err.response.data.message;
                    } 
                } else {
                    addwebsiteDataError.additionalService = true;
                }
            }
            
        }

        /**
         * Back feature form steps
         */
        const backtoDetails = () => {
            addNewStep.websiteDetails = true;
            addNewStep.payment = false;
        }

        /**
         * Coupon apply on the time payment
         */
        const applyCoupon = async() => {
        try {
            const response = await axios.post( userPortalValidateCoupon, {
                couponeCode: addwebsiteData.coupon
            }, {
                headers: {
                  apiKey: apiKey,
                  token: store.getters.customerPortalToken
                },
            });
            couponPayloadData.status = response.data.status;
            couponPayloadData.message = response.data.status === 200 ? response.data.data : response.data.message
        } catch (error) {
            couponPayloadData.status = error.response.data.status;
            couponPayloadData.message = error.response.data.status === 200 ? error.response.data.data : error.response.data.message
            }
        }

        /**
         * Installation guide popup intialization steps
         */
        const openInstallationPopup = () => {
            installationPopup.isOpen = true;
            let UserData = JSON.parse(localStorage.getItem("customer-details"));
            installationPopup.name = UserData.name;
            installationPopup.email = UserData.email;
            installationPopup.siteName = addwebsiteData.name;
            installationPopup.siteUrl = addwebsiteData.url;
        }

        /**
         * Installation popup resquest form valition steps
         * Submit installing form API call
         */
        const submitInstallationRequest = async() => {
            if (installationPopup.message === '') {
                installationPopup.messageEmpty = true;
            } else {
                installationPopup.messageEmpty = false;
            }
            if (!installationPopup.messageEmpty) {
                try {
                    const response = await axios.post(userPortalWebsiteInstallationGuide, {
                        name: installationPopup.name,
                        email: installationPopup.email,
                        websiteName: installationPopup.siteName,
                        websiteUrl: installationPopup.siteUrl,
                        message: installationPopup.message,
                    }, {
                        headers: {
                            apiKey: apiKey,
                            token: store.getters.customerPortalToken
                        },
                    });
                    if (response.status === 200) {
                        toast.add({severity:'success', summary: 'Request Sent!', detail: response.data.data, life: 5000});
                        installationPopup.isOpen = false;
                        installationPopup.name = '';
                        installationPopup.email = '';
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }

        return{
            imageUrlFrontEnd,
            addNewStep,
            packageType,
            addwebsiteData,
            addwebsiteDataError,
            apiResponse,
            userCardStatus,
            installationPopup,
            WebsiteAddStep,
            changeInputValue,
            backtoDetails,
            applyCoupon,
            openInstallationPopup,
            submitInstallationRequest,
            couponPayloadData,
            closeDialog
        }
    }
}
</script>


<style lang="scss" scoped>
@import '../../../../assets/style/component/userportal/pages/addNewSite/AddNewSite';
</style>