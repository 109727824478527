<template>
    <div class="up_rate_container">
        <div class="p-grid p-mb-4">
            <div class="p-col-12 p-sm-6 up_plan_tier_note">
                <p>Our pricing plan scales with your business - with the full feature set available to you at any level. Pricing increases alongside pageviews, with a free tier for sites with low visitation all the way to custom options for Enterprise clients.</p>
            </div>
            <div class="p-col-12 p-sm-6 p-d-flex p-align-center p-jc-end">
                <span class="text-primary up_info_text p-d-flex p-align-center" v-tooltip.top="'A pageview (or pageview hit, page tracking hit) is an instance of a page being loaded (or reloaded) in a browser. Pageviews is a metric defined as the total number of pages viewed.'">
                    <i class="pi pi-question-circle"></i> How do we count pageviews?
                </span>
            </div>
        </div>
        <div v-if="packageInitState.screenRes > 768 && packageInitState.data.length > 0" class="up_plan_matrix_desktop">
            <Carousel :settings='settings'>
                <Slide v-for="(type, i) of packageInitState.data" :key="i">
                    <div class="carousel__item up_plan_inner" >
                        <h3>{{type.packageType}} Pricing</h3>
                        <div class="up_plan_detail_cotent">
                            <div class="p-grid up_plan_cat">
                                <div class="p-col-3" v-for="(plan, j) of type.package" :key="j">
                                    <h4>{{plan.name}}</h4>
                                    <div class="up_plan_rate" v-if="plan.hits < 1000001"><sup>$</sup>{{plan.price}}<small>/mo</small></div>
                                    <div class="up_plan_rate_custom" v-else>Custom Pricing for Enterprise</div>
                                    <div class="up_plan_short_desc" v-html="plan.shortDetails"></div>
                                    <!-- <div class="up_plan_short_desc" v-if="plan.hits > 1000000">Custom Pricing for Enterprise</div> -->
                                </div>
                            </div>
                            <div class="up_plan_feature" v-html="type.package[0].details">
                            </div>
                        </div>
                    </div>
                </Slide>

                <template #addons>
                    <Pagination />
                </template>
            </Carousel>
        </div>
        <div v-if="packageInitState.screenRes < 768 && packageInitState.data.length > 0" class="up_plan_matrix_mobile">
            <Carousel :settings='settings'>
                <Slide v-for="(type, i) of packageInitState.data" :key="i">
                    <div class="carousel__item up_plan_inner" >
                        <h3>{{type.packageType}} Pricing</h3>
                        <div class="up_plan_detail_cotent">
                                <div :class="['up_plan_accordion', packageInitState.activeAccor === j ? 'up_plan_accordion_active' : '']" v-for="(plan, j) of type.package" :key="j" @click="toggleAccordion(j)">
                                    <div class="up_plan_header">
                                        <h4>{{plan.name}}</h4>
                                        <div class="up_plan_rate" v-if="plan.hits < 1000001"><sup>$</sup>{{plan.price}}<small>/mo</small></div>
                                        <div class="up_plan_rate_custom" v-else>Custom Pricing for Enterprise</div>
                                        <div class="up_plan_short_desc" v-html="plan.shortDetails"></div>
                                    </div>
                                    <div :class="['up_plan_body', packageInitState.activeAccor === j ? 'up_plan_show' : '']" v-html="type.package[0].details">
                                    </div>
                                </div>
                        </div>
                    </div>
                </Slide>

                <template #addons>
                    <Pagination />
                </template>
            </Carousel>
        </div>
        <div class="up_plan_tier_fnote">
            <p>**Pageviews beyond the above mentioned tiers custom pricing is available.</p>
        </div>
    </div>
</template>

<script>
import { onBeforeMount, reactive, ref } from 'vue';
import { allPackage } from '../../../config/appUrls.js';
import { apiKey } from '../../../config/constant.js';
import axios from "axios";
import { useStore } from 'vuex';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default {
    name: "RateTiers",
    components: {
        Carousel,
        Slide,
        Pagination
    },
    setup(){
        //Application store call
        const store = useStore();

        //Package initial state
        const packageInitState = reactive({
            data: [],
            screenRes: null,
            activeAccor: null
        });

        // carousel settings
        const settings = ref({
            itemsToShow: 1,
            itemsToScroll: 1,
            snapAlign: 'start',
        });

        onBeforeMount(()=>{
            packageInitState.screenRes = document.body.clientWidth;
            window.addEventListener('resize', getWidth);
            fetchAllPackages();
        });

        /**
         * 
         * Get dynamic document width
         * 
         */
        const getWidth = () => {
            packageInitState.screenRes = document.body.clientWidth;
        }

        /**
         * 
         * Fetching package data form server
         * 
         */
        const fetchAllPackages = async () =>{

            try{
                const response = await axios.get( allPackage, {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                let tempArr = [];
                for (let i = 0; i < response.data.data.packages.length; i++) {
                    let exist = tempArr.filter(item => item.packageType === response.data.data.packages[i].Packagetype.name);
                    if(response.data.data.packages[i].packageName !== "Freemium"){
                        if(exist.length){
                            let index = tempArr.indexOf(exist[0]);
                            tempArr[index].package.push({
                                details: response.data.data.packages[i].details,
                                name: response.data.data.packages[i].packageName,
                                hits: response.data.data.packages[i].hits,
                                price: response.data.data.packages[i].price,
                                shortDetails: response.data.data.packages[i].shortDetails
                            });
                        } else {
                            tempArr.push({
                                packageType: response.data.data.packages[i].Packagetype.name,
                                package: [{
                                    details: response.data.data.packages[i].details,
                                    name: response.data.data.packages[i].packageName,
                                    hits: response.data.data.packages[i].hits,
                                    price: response.data.data.packages[i].price,
                                    shortDetails: response.data.data.packages[i].shortDetails
                                }]
                            })
                        }
                    } 
                }
                for (let p = 0; p < tempArr.length; p++) {
                    tempArr[p].package.sort(sortPrice);      
                }
                packageInitState.data = tempArr.sort(sortPackage);
            } catch(err){
                console.log('error', err);
            }
        }

        /**
         * 
         * Sorting package alphabetically
         * 
         */
        const sortPackage = (a, b) => {
            let x = a.packageType.toLowerCase();
            let y = b.packageType.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        }

        /**
         * 
         * Sorting package price ascending
         * 
         */
        const sortPrice = (a, b) => {
            return a.price - b.price;
        }

        /**
         * 
         * Mobile plan view toggling
         * 
         */
        const toggleAccordion = (index) => {
            packageInitState.activeAccor = index === packageInitState.activeAccor ? null : index;
        }

        return{
            packageInitState,
            settings,
            toggleAccordion
        }
    }
}
</script>

<style lang="scss">
.carousel__slide{
    align-items: flex-start;
}
@import '../../../assets/style/component/userportal/views/RateTiers';
</style>